/* eslint-disable no-case-declarations */
import axios from "axios";
import Row from "@/data/Row";
import RowSingle from "@/data/RowSingle";
import RowVertical from "@/data/RowVertical";
import RowHorizontal from "@/data/RowHorizontal";
import DynamicLayout from "../data/DynamicLayout";
import { DynamicLayoutType } from "../data/DynamicLayoutType";
import { Languages } from "../data/Languages";
import DeviceService from "./DeviceService";
import Image from "../data/Image";
import { Publish_GalleryViewModel } from "@/data/publish/Publish_GalleryViewModel";
import { GeneralStateStore } from "@/store/states/GeneralState";
import { Publish_RouteViewModel } from "@/data/publish/Publish_RouteViewModel";

export default class RequestService {
  private deviceService: DeviceService;
  private readonly store: GeneralStateStore;

  constructor(deviceService: DeviceService, store: GeneralStateStore) {
    this.deviceService = deviceService;
    this.store = store;
  }

  private parseGridLayout(data: Publish_GalleryViewModel): Array<Row> {
    const layout: Array<Row> = [];
    for (const row of data.rows) {
      const images: Array<Image> = [];
      for (const img of row.photos) {
        const name = img.onDiskFileName;
        const ext = this.deviceService.isWebPSupported() ? "webp" : "jpeg";
        const image = new Image(
          `${this.store.dataSourceUrl}/images/${name}.min.${ext}`,
          Number.parseInt(img.width as any),
          Number.parseInt(img.height as any)
        );
        images.push(image);
      }
      switch (row.photos.length) {
        case 1:
          layout.push(new RowSingle(images[0]));
          break;
        case 2:
          layout.push(new RowHorizontal(images[0], images[1]));
          break;
        case 3:
          layout.push(new RowVertical(images[0], images[1], images[2]));
          break;
      }
    }
    return layout;
  }

  public async getLayout(galleryId: string): Promise<DynamicLayout> {
    const response = await axios.get(
      `${
        this.store.dataSourceUrl
      }/publish/gallery.${galleryId}.json?v=${encodeURIComponent(
        this.store.publishId
      )}`
    );
    const layout: Array<Row> = this.parseGridLayout(response.data);
    return new DynamicLayout(DynamicLayoutType.Grid, layout);
  }

  public async getRoutes(): Promise<Array<Publish_RouteViewModel>> {
    const response = await axios.get(
      `${this.store.dataSourceUrl}/publish/routes.json?v=${encodeURIComponent(
        this.store.publishId
      )}`
    );
    return response.data as Array<Publish_RouteViewModel>;
  }

  public async getPublishId(): Promise<string> {
    const response = await axios.post(
      `${this.store.dataSourceUrl}/api/Publish/GetPublishId`
    );
    return response.data;
  }

  public async getTranslations(
    language: Languages
  ): Promise<Record<string, string>> {
    const response = await axios.get(
      `${
        this.store.dataSourceUrl
      }/publish/${language}.json?v=${encodeURIComponent(this.store.publishId)}`
    );
    return response.data;
  }

  public async getPublishJson(): Promise<Array<Publish_GalleryViewModel>> {
    const response = await axios.get(
      `${this.store.dataSourceUrl}/publish/publish.json?v=${encodeURIComponent(
        this.store.publishId
      )}`
    );
    return response.data;
  }
}
