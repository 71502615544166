import isMobile from "ismobilejs";

export default class DeviceService {
  private static isWebPSupported?: boolean;

  public isMobileDisplay(): boolean {
    const mobileDetector = isMobile(window.navigator.userAgent);
    return (
      window.innerWidth < 768 || mobileDetector.tablet || mobileDetector.phone
    );
  }

  public isElementOutOfViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top < 0 ||
      rect.bottom > document.documentElement.clientHeight ||
      rect.left < 0 ||
      rect.right > document.documentElement.clientWidth
    );
  }

  public isElementVisibleInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= document.documentElement.clientHeight ||
      rect.right >= document.documentElement.clientWidth
    );
  }

  public isApple(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const vendor = window.navigator.vendor.toLowerCase();
    const platform = window.navigator.platform;

    const isSafari =
      /^((?!chrome|android).)*safari/i.test(userAgent) ||
      (vendor.includes("apple") &&
        /version\/([0-9._]+).*safari/.test(userAgent));

    const isIOS =
      /iPad|iPhone|iPod/.test(platform) ||
      (platform === "MacIntel" && navigator.maxTouchPoints > 1);

    const isMac = platform.toLowerCase().includes("mac");
    const isAppleVendor = vendor.includes("apple");

    return isSafari || isIOS || isMac || isAppleVendor;
  }

  public isWebPSupported(): boolean {
    if (DeviceService.isWebPSupported != null) {
      return DeviceService.isWebPSupported;
    }

    if (this.isApple()) {
      DeviceService.isWebPSupported = false;
      return false;
    }

    const canvas = document.createElement("canvas");
    if (!!(canvas.getContext && canvas.getContext("2d"))) {
      DeviceService.isWebPSupported =
        canvas.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    } else {
      DeviceService.isWebPSupported = false;
    }
    canvas.remove();

    return DeviceService.isWebPSupported;
  }

  public hideScroll(): void {
    document.documentElement.className = "";
    document.body.style.overflowY = "hidden";
  }

  public disableScroll(): void {
    document.documentElement.className = "html__show-disabled-scroll";
    document.body.style.overflowY = "hidden";
  }

  public enableScroll(): void {
    document.documentElement.className = "";
    document.body.style.overflowY = "unset";
  }
}
